import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { getValidOrganization } from '@/utils/helpers'
import oidc from '@/utils/config/oidc'

export default {
  data: () => ({
    isOAuthSignupInProgress: false,
    checkGitHubToken: 0,
    oidcErrors: [],
    oidcLoading: {
      azuread: false,
      microsoft: false,
      google: false,
      github: false,
    },
    popUpInterval: null,
  }),
  computed: {
    ...mapState({
      organizations: (state) => state.organizations,
      appConfig: (state) => state.appConfig,
    }),
    ...mapState('auth', {
      ssoErrors: (state) => state.errors.sso,
      sso: (state) => state.sso,
      socialProvider: (state) => state.socialProvider,
    }),
    ...mapGetters([
      'hasOrgs',
      'socialProviders',
    ]),
    ...mapGetters('auth', [
      'ssoUserProfile',
      'defaultRoute',
    ]),
    hasOAuthProviders () {
      if (this.awsMarketplaceToken) return false
      return !_.$isEmpty(this.socialProviders)
    },
    hasSaml2Providers () {
      if (this.awsMarketplaceToken) return false
      return !_.$isEmpty(this.appConfig?.authentication?.saml2)
    },
    hasSSOProviders () {
      if (this.awsMarketplaceToken) return false
      const { hasOAuthProviders, hasSaml2Providers } = this
      return hasOAuthProviders || hasSaml2Providers
    },
  },
  async mounted () {
    await this.GET_APP_CONFIG()

    if (_.isEmpty(this.appConfig)) return

    if (this.hasOAuthProviders) {
      oidc.setup(this.appConfig?.authentication?.oauth)
      for (const socialProvider in oidc.providers) oidc.providers[socialProvider].events.addUserLoaded(this.handleResFromOAuthProvider)
      if (this.isOauthSignupRedirect) this.setOAuthUser()
    }
  },
  destroyed () {
    for (const socialProvider in oidc.providers) oidc.providers[socialProvider].events.removeUserLoaded(this.handleResFromOAuthProvider)
    clearInterval(this.popUpInterval)
  },
  methods: {
    ...mapActions([
      'GET_APP_CONFIG',
    ]),
    ...mapActions('auth', [
      'GET_SSO_USER',
      'SSO_SIGNUP',
      'LOGIN',
    ]),
    ...mapActions('oidc', [
      'authenticateOidcPopup',
    ]),
    ...mapActions('organization', [
      'CHANGE_ORGANIZATION',
    ]),
    ...mapMutations('auth', [
      'SET_SOCIAL_PROVIDER',
      'SET_AWS_MARKETPLACE_TOKEN',
      'SET_AWS_MARKETPLACE_SUBSCRIPTION',
    ]),
    ...mapMutations('organization', [
      'SET_ORGANIZATION',
    ]),
    setOAuthUser () {
      this.user = _.merge(this.user, _.$camelCaseKeys(this.ssoUserProfile))

      if (this.socialProvider === 'github') {
        const [givenName, familyName] = this.user.givenName.split(' ')
        this.user = { ...this.user, givenName, familyName }
      }

      this.$v.user.$touch()
    },
    getOAuthUser () {
      const { user: { username, email, givenName, familyName, socialId, countryCode, locale } = {}, invitationToken } = this

      return this.isOAuthSignupInProgress
        ? _.$snakeCaseKeys({ username, email, givenName, familyName, socialId, invitationToken, countryCode, locale })
        : _.$snakeCaseKeys({ ...this.ssoUserProfile, invitationToken, countryCode, locale })
    },
    async startOAuthFlow (socialProvider) {
      this.$set(this, `oidcLoading.${socialProvider}`, true)
      this.SET_SOCIAL_PROVIDER(socialProvider)

      try {
        socialProvider === 'github'
          ? this.authWithGitHub()
          : await oidc.providers[socialProvider].signinPopup()
      } catch (error) {
        const { name: code, message } = error
        this.oidcErrors = [{ code, message }]
      }
      socialProvider !== 'github' && (this.oidcLoading[socialProvider] = false)
    },
    authWithGitHub () {
      const clientId = this.appConfig?.authentication?.oauth?.github?.client_id

      window.open(`https://github.com/login/oauth/authorize?client_id=${clientId}&scope=user`, 'github', 'height=700,width=450')
      this.popUpInterval = setInterval(this.hasGitHubUserSignedIn, 200)
    },
    hasGitHubUserSignedIn () {
      localStorage.github_id_token && this.handleGitHubSignIn()
      this.checkGitHubToken++
      if (this.checkGitHubToken === 50) {
        this.oidcLoading.github = false
        this.checkGitHubToken = 0
        clearInterval(this.popUpInterval)
      }
    },
    async handleGitHubSignIn () {
      clearInterval(this.popUpInterval)
      await this.handleResFromOAuthProvider({ id_token: localStorage.github_id_token })
      localStorage.removeItem('github_id_token')
      this.oidcLoading.github = false
    },
    async handleResFromOAuthProvider (event) {
      const { socialProvider } = this
      await this.GET_SSO_USER({ socialProvider, ssoCode: { oauth_code: event.id_token } })

      if (!_.isEmpty(this.ssoErrors)) return

      if (_.has(this.sso, 'user')) {
        await this.startOAuthSignup()
      } else {
        await this.LOGIN()
        if (socialProvider === 'github') {
          localStorage.closePopUp = 'true'
        }
        this.hasOrgs
          ? await this.redirectToDefaultRoute()
          : this.redirectToOrgsList()
      }
    },
    isUserProfileComplete (user) {
      const requiredFields = ['email', 'given_name', 'family_name', 'social_id', 'username', 'country_code', 'locale']
      return _.every(requiredFields, (requiredField) => _.has(user, requiredField) && !_.isEmpty(user[requiredField]))
    },
    async startOAuthSignup () {
      this.loading = true
      const { socialProvider, $router, sso: { user }, $route } = this

      if (this.isUserProfileComplete(user)) this.completeOAuthSignup()
      else {
        if ($route.name === 'login' || $route.name === 'githubCallback') $router.push({ name: 'signUp', params: { socialProvider, isOauthSignupRedirect: true } })
        if ($route.name === 'signUp') {
          this.isOAuthSignupInProgress = true
          this.setOAuthUser()
        }
      }
      this.loading = false
    },
    async completeOAuthSignup () {
      this.loading = true
      const { socialProvider } = this
      const userFromProvider = this.getOAuthUser()
      const user = { ...userFromProvider }
      await this.SSO_SIGNUP({ socialProvider, user })
      if (_.isEmpty(this.ssoErrors)) {
        await this.LOGIN()

        this.hasOrgs
          ? await this.redirectToDefaultRoute()
          : this.redirectToOrgsList()
      }

      this.loading = false
    },
    redirectToOrgsList () {
      this.SET_ORGANIZATION()
      this.$router.push({ name: 'organizations' })
    },
    async redirectToDefaultRoute () {
      const { orgCanonical } = this.$route.params || {}
      const nextCanonical = getValidOrganization(this.organizations, orgCanonical).canonical
      await this.CHANGE_ORGANIZATION({ nextCanonical })
      this.$router.push(this.defaultRoute).catch(() => {})
    },
    navigateToSaml2Provider (ssoUrl) {
      window.location.href = ssoUrl
    },
    getAwsMarketplaceToken () {
      const awsMarketplaceToken = this.$route.query.aws_marketplace_token
      const expiresAt = this.$route.query.expires_at
      const membersCount = this.$route.query.members_count
      const planCanonical = this.$route.query.plan_canonical
      this.SET_AWS_MARKETPLACE_TOKEN(awsMarketplaceToken)
      this.SET_AWS_MARKETPLACE_SUBSCRIPTION({ expiresAt, membersCount, planCanonical })
    },
  },
}
